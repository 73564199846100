import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// Components
import PartnersInvestors from '../components/partners-investors.js';
import Meta from '../components/meta.js';
import BigBanner from '../components/big-banner.js';
import Awards from '../components/awards.js';

const totalImages = 3;
const changeTime = 5000;

const images = [
  {id: 'btn-circle-phone-0', path: '/img/home/volta-mobile-app-0.png'},
  {id: 'btn-circle-phone-1', path: '/img/home/volta-mobile-app-1.png'},
  {id: 'btn-circle-phone-2', path: '/img/home/volta-mobile-app-2.png'},
];

const PhoneCircles = ({selectedIndex, selectBox}) => {
  const style = {
    background: 'white',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    borderRadius: '100%',
    border: '1px solid #DBDBDB',
  }

  const selectedStyle = {
    ...style,
    border: '5px solid #6AB42D',
  }

  return(
    <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: '30px'}}>
      <div style={{display: 'flex', width: '90px', justifyContent: 'space-between'}}>
        <div id="btn-circle-phone-0" onClick={selectBox} style={selectedIndex === 0 ? selectedStyle : style} />
        <div id="btn-circle-phone-1" onClick={selectBox} style={selectedIndex === 1 ? selectedStyle : style} />
        <div id="btn-circle-phone-2" onClick={selectBox} style={selectedIndex === 2 ? selectedStyle : style} />
      </div>
    </div>
  )
}

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
    };
  }

  componentDidMount(){
    this.turnAutoChangeOn();
  }

  componentWillUnmount(){
    clearTimeout(this.timeoutId);
  }

  turnAutoChangeOn = () => {
    this.timeoutId = setTimeout(this.autoChangeImage, changeTime);
  }

  selectBox = (e) => {
    if (e && e.target && e.target.getAttribute){
      const clickedButton = e.target.getAttribute('id');
      const imageIndex = images.findIndex(image => image.id === clickedButton);
      if (this.state.selected && imageIndex === this.state.currentImage){
        this.unselectBox();
      }else{
          clearTimeout(this.timeoutId);
          this.setState({
            currentImage: imageIndex,
            selected: true,
          });
        }
    }
  }

  unselectBox = () => {
    clearTimeout(this.timeoutId);
    this.setState({
      selected: false
    });
    this.autoChangeImage();
  }

  autoChangeImage = () => {
    this.setState({
      currentImage: (this.state.currentImage + 1) % totalImages
    });

    this.timeoutId = setTimeout(this.autoChangeImage, changeTime);
  }

  render() {
    const {currentImage} = this.state;
    return (
      <div id="home" className="volta-page">
        <Meta
          title="Energy Data Intelligence For Utilities | Voltaware"
          description="Voltaware monitors your energy use in real-time to help you save energy, reduce carbon footprint and make your home safer."
          url="https://www.voltaware.com"
        />

        <BigBanner
          image="home/background-banner-mobile.jpg"
          mode="centred"
          mobile
        >
          <h1>
            Accelerating<br />
            Smart Energy<br />
            Decisions for<br />
            Households
          </h1>
        </BigBanner>

        <BigBanner
          image="home/background-banner-2024-09.jpg"
          shouldHideForMobile
        >
          <h2 className="h1">
            Accelerating Smart Energy
            Decisions for Households
          </h2>
        </BigBanner>

        <div className="text-section white no-padding-bottom">
          <div id="introducing-voltaware" className="single-column-wrapper medium-wrapper">
            <h2 className="h1">Introducing Voltaware</h2>
            <p>
              We are revolutionising the way people interact with their energy use. Our mission is to use electricity data to make homes more efficient,
              greener, reliable and secure.
              Voltaware delivers actionable energy insights to help users save energy and improve their comfort at home.
            </p>
          </div>
        </div>
        <div className="text-section white no-padding-top">
          <div className="buttons-section small-padding-top single-column">
            <Link
              to="/our-technology"
              title="How Does It Work?"
              className="btn-outlined"
            >
              How Does It Work?
            </Link>
            <Link
              to="/about-us"
              title="About Us"
              className="btn-outlined dark-grey"
            >
              About Us
            </Link>

            <HashLink
              to="/#how-to-get-voltaware"
              title="Get Voltaware"
              className="btn-outlined dark-grey"
              smooth
              scroll={el => {
                setTimeout(() => {
                  el.scrollIntoView({behavior: 'smooth', block: 'center'});
                }, 1)
              }}
            >
              Get Voltaware
            </HashLink>
          </div>
        </div>

        <div className="text-section light-grey">
          <div className="single-column-wrapper medium-wrapper">
            <h2 className="h1 dark-grey">Take control of your energy usage</h2>
            <p>
              Voltaware uses AI to track your energy use down to the appliance level. This enables you to get on top of your electricity bill, reduce
              your carbon footprint, make your home safer, monitor elderly relatives, and much more…
            </p>
          </div>
        </div>
        <div className="text-section light-grey">
          <div id="volta-app-features" className="two-columns-wrapper narrow-text-box">
            <div>
              <img src={images[currentImage].path} alt="Phone with Voltare app" />
              <PhoneCircles selectedIndex={currentImage} selectBox={this.selectBox} />
            </div>
            <div className="text-box">
              <h2 className="dark-green">Understand your energy use</h2>
              <p>
                Voltaware uses AI to deliver energy insights down to the appliance level so that you can streamline your energy usage.
              </p>
              <h2 className="dark-green">Tackle energy waste</h2>
              <p>
                Voltaware allows you to understand your efficiency levels against similar homes, your energy tariffs and your historical progress.
              </p>
              <h2 className="dark-green">Safety & security</h2>
              <p>
                Voltaware remotely tracks your home activity through real-time safety & security to prevent crime, unpleasant surprises and avoid potential
                fire hazards.
              </p>
              <h2 className="dark-green">Real-time monitoring</h2>
              <p>
                Voltaware tracks your energy use in real-time and will send you intelligent alerts and notifications based on cost, consumption and surge.
              </p>
              <h2 className="dark-green">Future-proof</h2>
              <p>
                As Voltaware collects more data, we are able to release new features and deliver deeper insights.
              </p>
            </div>
          </div>
        </div>

        <div className="text-section white no-padding-top no-padding-bottom">
          <div className="single-column-wrapper medium-wrapper">
            <h2 className="h1">Works with Smart Meters</h2>
            <p>
              Voltaware has a range of meter dongles which are self-installable and low cost - enabling high resolution energy insights for smart meter owners.
            </p>
          </div>

          <div className="single-column-wrapper medium-wrapper">
            <img
              className="small-padding-top small-padding-bottom"
              src="/img/home/smart-meter.png"
              alt="Voltaware works with smart meters"
              style={{maxWidth: 460}} />
          </div>
            
          <div className="buttons-section small-padding-top small-padding-bottom">
              <Link
                to="/our-technology/smart-meters-integration"
                title="Smart Meter Compatibility"
                className="btn-contained inverted-colors"
              >
                Smart Meter Compatibility
              </Link>
            </div>
        </div>

        <div className="text-section light-grey no-padding-bottom">
          <div className="single-column-wrapper medium-wrapper">
            <h2 className="h1">Voltaware sensors</h2>
            <p>
              Credit-card sized, the patented Voltaware sensors can be installed in any fusebox in 5 minutes. Voltaware sensors collect high resolution data
              and monitor your energy use in real-time.
            </p>
          </div>
        </div>
        <div className="text-section light-grey">
          <div className="two-columns-wrapper medium-wrapper two-images">
            <img src="/img/home/voltaware-sensor-single-phase-wifi.svg" alt="Wifi - connected Single Phase Voltaware Sensor" />
            <img src="/img/home/voltaware-sensor-features.svg" alt="Voltaware Sensor Features: easy installation, credit-card sized, meter grade accuracy, Wifi/GSM" />
          </div>
        </div>
        <div className="text-section light-grey no-padding-top">
          <div className="buttons-section small-padding-top">
            <Link
                to="/our-technology/voltaware-sensors"
                title="What is the Voltaware sensor"
                className="btn-outlined dark-grey"
            >
              What is the Voltaware sensor?
            </Link>
          </div>
        </div>

        <div className="text-section white">
          <div className="single-column-wrapper medium-wrapper">
            <h2 className="h1 dark-grey">AI for utilities</h2>
            <p>
              Voltaware AI platform for utilities boosts customer engagement. It addresses their customers’ needs and unlock new revenues.
            </p>
          </div>
        </div>
        <div className="text-section white no-padding-bottom">
          <div id="ai-for-utilities" className="two-columns-wrapper large-wrapper narrow-text-box">
            <img src="/img/home/family-on-couch-picture.jpg" alt="Family on couch" />
            <div className="text-box">
              <h2 className="dark-green">Personalise customer engagement</h2>
              <p>
                Voltaware delivers personalised energy and home insights to engage your customers and keep them satisfied.
              </p>
              <h2 className="dark-green">Unlock new revenues</h2>
              <p>
                Utilities can build new products and unlock data-based monetisation opportunities.
              </p>
              <h2 className="dark-green">Inform business decisions</h2>
              <p>
                Energy intelligence to identify trends and make better-informed product and business decisions.
              </p>
              <h2 className="dark-green">Seamless customisation</h2>
              <p>
                Voltaware AI platform allows utilities to integrate electricity data directly into their consumer-facing application.
              </p>
              <h2 className="dark-green">Continuous learning</h2>
              <p>
                As we grow our user base and gain more knowledge, Voltaware is able to develop new use cases and create more engagement.
              </p>
            </div>
          </div>
        </div>
        <div className="text-section white no-padding-top">
          <div className="buttons-section small-padding-top small-padding-bottom">
            <Link
              to="/our-offer-to-utilities"
              title="Offer to Utility"
              className="btn-outlined"
            >
              For utilities
            </Link>
            <Link
              to="/our-technology/cloud-for-utilities"
              title="Integrate with Voltaware"
              className="btn-outlined dark-grey"
            >
              AI platform for utilities
            </Link>
          </div>
        </div>

        <div id="how-to-get-voltaware" className="text-section light-grey">
          <div className="single-column-wrapper small-wrapper">
            <h2 className="h1">How to get Voltaware</h2>
            <p>
              If you are a utility, please get in touch with us. If you are a homeowner, you can contact our utilities and ecosystem partners listed below.
            </p>

            <div className="buttons-section small-padding-top">
              <Link
                to="/request-a-demo"
                title="Request a Demo"
                className="btn-contained call-to-action"
              >
                Request a Demo
              </Link>
            </div>
          </div>
        </div>

        <div className="text-section white">
          <h2 className="h1">Rollout partners & investors</h2>
          <PartnersInvestors />
        </div>
        <div className="text-section white">
          <h2 className="h1">Awards</h2>
          <Awards />
        </div>

      </div>
    );
  }
}

export default Home;

import React from 'react';
import { withRouter } from 'react-router-dom';

class FaqItem extends React.Component {
  state = {
    selectedQuestion: null,
  };

  componentDidMount() {
    const { location, items } = this.props;
    const hash = location?.hash?.replace('#', '');

    if (hash) {
      const scrollToHash = () => {
        const found = items.find(
          (item) => this.transformQuestionId(item.question) === hash
        );
        if (found) {
          this.toggle(found.question);
          const targetElement = document.getElementById(hash);
          if (targetElement) {
            const isMobile = window.innerWidth <= 768;
            const yOffset = isMobile ? -90 : -120;
            const yPosition =
              targetElement.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({
              top: yPosition,
              behavior: 'smooth',
            });
          }
        }
      };

      requestAnimationFrame(() => {
        setTimeout(scrollToHash, 1000);
      });
    }
  }

  toggle = (question, e) => {
    if (e) e.preventDefault();

    this.setState(
      (prevState) => ({
        selectedQuestion:
          prevState.selectedQuestion === question ? null : question,
      }),
      () => {
        if (this.state.selectedQuestion === question) {
          // Scroll to the newly opened question
          const targetElement = document.getElementById(
            this.transformQuestionId(question)
          );

          if (targetElement) {
            const isMobile = window.innerWidth <= 768;
            const yOffset = isMobile ? -90 : -120;
            const yPosition =
              targetElement.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({
              top: yPosition,
              behavior: 'smooth',
            });
          }
        }
      }
    );
  };

  transformQuestionId = (question) => {
    return question
      .toLocaleLowerCase()
      .replace(/ /g, '-')
      .replace(/'|"|\(|\)|\.|,|\/|\?/g, '')
      .replace(/\n/g, '');
  };

  render() {
    const { selectedQuestion } = this.state;

    return (
      <div className="faq-item">
        <h4 className="faq-title">{this.props.title}</h4>
        <div className="faq-content">
          {!!this.props.observation && (
            <p dangerouslySetInnerHTML={{ __html: this.props.observation }}></p>
          )}
          <ul>
            {this.props.items.map((obj, i) => {
              const isSpecialQuestion =
                obj.question ===
                  'Does Voltaware work with solar panels installed at home?' ||
                obj.question ===
                  'Can My Sensor Measure Solar Energy Production?';

              return (
                <li
                  key={i}
                  id={
                    isSpecialQuestion
                      ? this.transformQuestionId(obj.question)
                      : undefined
                  }
                  className={obj.question === selectedQuestion ? 'active' : ''}
                >
                  <div className="question">
                    <img src="/img/bullet-arrow.svg" alt="list bullet" />
                    <button
                      type="button"
                      onClick={(e) => this.toggle(obj.question, e)}
                    >
                      {obj.question}
                    </button>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{ __html: obj.answer }}
                    className="answer"
                  ></p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(FaqItem);
